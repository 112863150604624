
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'passive'
    },
    type: {
      type: String,
      default: 'text'
    },
    rules: {
      type: [String, Object],
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    val: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    resetErrors() {
      this.$refs.input.reset()
    }
  }
}
