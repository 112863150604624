
import TsInput from '@/components/layout/Form/Input'
export default {
  components: {
    TsInput
  },
  data() {
    return {
      form: {
        login: '',
        password: ''
      }
    }
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.$api.auth
          .use('data')
          .login(this.form)
          .then(async (response) => {
            await this.$store.dispatch('SET_CLIENT_ID', response.uid)
            const client = await this.$api.auth.use('data').client()
            await this.$store.dispatch('SET_CLIENT', client)
            this.$router.push(
              this.localePath(this.$store.getters['router/backLink'])
            )
          })
      }
    }
  }
}
