
import AuthContainer from '@/components/layout/Auth'
import SignIn from '@/components/Form/Signin'
import SocialAuth from '@/components/Auth/Social'
export default {
  name: 'Login',
  components: {
    AuthContainer,
    SignIn,
    SocialAuth
  },
  layout: 'empty',
  middleware: ['login']
}
