
export default {
  methods: {
    async success(response) {
      await this.$store.dispatch('SET_CLIENT_ID', response.uid)
      const client = await this.$api.auth.use('data').client()
      await this.$store.dispatch('SET_CLIENT', client)
      this.$router.push(this.localePath(this.$store.getters['router/backLink']))
    },
    facebook() {
      this.$auth.facebook({
        appId: this.$config.FACEBOOK_ID,
        callback: (token) => {
          this.$api.auth
            .use('data')
            .facebook({
              access_token: token
            })
            .then((response) => this.success(response))
        }
      })
    },
    gis() {
      this.$auth.gis({
        client_id: this.$config.GOOGLE_ID,
        callback: (token) => {
          this.$api.auth
            .use('data')
            .google({
              access_token: token
            })
            .then((response) => this.success(response))
        }
      })
    },
    google() {
      this.$auth.google({
        client_id: this.$config.GOOGLE_ID,
        callback: (token) => {
          this.$api.auth
            .use('data')
            .google({
              access_token: token
            })
            .then((response) => this.success(response))
        }
      })
    },
    apple() {
      this.$auth.apple({
        clientId: this.$config.APPLE_ID,
        redirectURI: `${this.$config.HOST}${this.localePath({
          name: 'login'
        })}`,
        callback: (params) => {
          this.$api.auth
            .use('data')
            .apple(params)
            .then((response) => this.success(response))
        }
      })
    }
  }
}
